.selected {
    padding-top: 18px;
    padding-bottom: 22px;
    padding-left: 8px!important;
    padding-right: 8px!important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    border-radius: 10px;
}

@media (max-width: 1055px) {
    .selected {
        margin-left: -15px !important;
    }
}
.divnot{
    box-shadow: 0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    margin-top: 0px;
    border-radius: 4px;
    z-index: 3;
    position: absolute;
    background-color: white;
    width: 300px;
    right: 0;
    min-height: 200px;
    max-height: 600px;
    padding: 6px;
    overflow-x: hidden;
    overflow-y: auto;

}