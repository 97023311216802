@media (max-width: 991px){
    .retail-top{
        margin-top: 20px;
    }
    .retail-btn{
        margin-top: 20px !important;
    }
    .retail-calc{
        margin-left: 20px !important;
    }
}
.incre{
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    border-radius: 50px;
    padding-left: 6px;
    width: 25px;
    height: 25px;
}
.decre{
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    border-radius: 50px;
    padding-left: 9px;
    width: 25px;
    height: 25px;
}
.incdiv{
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    border-radius: 8px;
    margin-top: 34px;
    margin-top: 34px;
    padding: 7px;
    padding-left: 15px;
    height: 41px;
    width: 127px;
}

.textbox-padding .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    padding-left: 12px!important;
    padding-right: 12px!important;
    padding-top: unset;
    padding-bottom: unset;
}
